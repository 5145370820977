import React from 'react'
import { sortBy } from 'lodash'
import { Link,graphql,useStaticQuery } from 'gatsby'

import * as styles from './navigation.module.css'

const Navigation = () => {
  const data = useStaticQuery(graphql`
  query StaticPages {
    allContentfulStaticPage(filter: {publishToMenu: {eq: true}}) {
      nodes {
        title
        slug
        weight
      }
    }
  }
  `)
  const pages = data.allContentfulStaticPage.nodes
  
  const navItems = pages.map((page) => {
    return (
    {
      linkText: page.title,
      linkURL: `/${page.slug}/`,
      linkOrder: page.weight
    })
  })
  navItems.push(
    {
      linkText: "Home",
      linkURL: '/',
      linkOrder: 0
    },
    {
      linkText: "Articles",
      linkURL: '/blog/',
      linkOrder: 2
    }
  )
  const sortedNavItems = sortBy(navItems,['linkOrder','linkText'])

  return (
    <nav role="navigation" className={styles.container} aria-label="Main">
      <Link to="/" className={styles.logoLink}>
        <span className={styles.logo} />
        <span className={styles.navigationItem}>Biblical Studies</span>
      </Link>
      <ul className={styles.navigation}>
        {sortedNavItems.map((navItem,index) => (
          <li className={styles.navigationItem}>
          <Link to={navItem.linkURL} activeClassName="active" key={index}>
            {navItem.linkText}
          </Link>
        </li>
        ))}
      </ul>
    </nav>
  )
}
export default Navigation